import React from 'react';
import { NavLink } from 'react-router-dom';
import NavButton from '../NavButton';
import { render } from '@testing-library/react';
import './match2.css';
import GameTimer from '../GameTimer';

/**
 * Symbol matching game
 * @param {*} props 
 * @returns 
 */

//const symbols = ['🍎', '🍌', '🍇', '🍉', '🍒', '🍓', '🍍', '🥝'];
//const symbols = ['🕵️‍♂️', '💥', '🔍', '🗝️', '📜', '💼', '💃🏽', '😎'];
const symbols = ['s1', 's2', 's3', 's4', 's5', 's6', 's7', 's8'];
const shuffledSymbols = [...symbols, ...symbols].sort(() => Math.random() - 0.5);

const Match2 = ({onComplete}) => {
    const [selectedSymbols, setSelectedSymbols] = React.useState([]);
    const [matchedSymbols, setMatchedSymbols] = React.useState([]);
    const [score, setScore] = React.useState(0);

    const handleSymbolClick = (index) => {
        if (selectedSymbols.length === 2) {
            setSelectedSymbols([]);
        }

        if (selectedSymbols.length < 2 && !selectedSymbols.includes(index)) {
            setSelectedSymbols([...selectedSymbols, index]);
        }

        if (selectedSymbols.length === 1 && shuffledSymbols[selectedSymbols[0]] === shuffledSymbols[index]) {
            setMatchedSymbols([...matchedSymbols, selectedSymbols[0], index]);
            setSelectedSymbols([]);
            setScore(score + 1); // Increment score by 1 for each match
        } 
    };

    React.useEffect(() => {
        if (matchedSymbols.length === shuffledSymbols.length) {
            onComplete(score);
        }
    }, [matchedSymbols, onComplete]);

    const renderSymbols = () => {
        return shuffledSymbols.map((symbol, index) => {
            const isSelected = selectedSymbols.includes(index);
            const isMatched = matchedSymbols.includes(index);
            return (
                <div
                    key={index}
                    className={`symbol ${isSelected || isMatched ? 'visible' : ''} ${isSelected || isMatched ? symbol : ''} ${!isSelected && !isMatched ? 's0' : ''}`}
                    onClick={() => handleSymbolClick(index)}
                >
                </div>
            );
        });
    };

    return (
        <div className="match2-game">
            <GameTimer initialSeconds={90} onTimerEnd={() => onComplete(score)} />
            <div className="score">Uncovered: {score} / 8</div> {/* Display the score */}
            <div className="symbols-container">
                {renderSymbols()}
            </div>
        </div>
    );
}

export default Match2;