import React, { useEffect, useMemo, useReducer } from 'react';
import L from 'leaflet';
import GameButton from './GameButton';
import { GameContext } from '../Context';

let watchid = 0;
let zoomlevel = 15;
let themap = null;
let locationMarker = null;
let targetMarker = null;
let targetCircle = null;
let svgarrow = null;
let timer = null;

//-33.913, 18.388
let testPositions = [{ lat: 0, lon: 0 }, { lat: 0, lon: 0 }, { lat: 0, lon: 0 }, { lat: 0, lon: 0 }];
function getRandomPosition(baseLat, baseLon, accuracy) {
    const randomOffset = () => (Math.random() - 0.5) * accuracy * 0.0001;
    return {
        lat: baseLat + randomOffset(),
        lon: baseLon + randomOffset()
    };
}

testPositions = Array.from({ length: 24 }, () => getRandomPosition(-33.9041358, 18.4114505, 20));

const greenIcon = L.icon({
    iconUrl: '/map/default.png',
    shadowUrl: '/map/default_shadow.png',

    iconSize: [35, 39], // size of the icon
    shadowSize: [50, 34], // size of the shadow
    iconAnchor: [22, 34], // point of the icon which will correspond to marker's location
    shadowAnchor: [20, 22],  // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

// error.code can be:
//   0: unknown error
//   1: permission denied
//   2: position unavailable (error response from location provider)
//   3: timed out
const Location = (props) => {

    const gameContext = React.useContext(GameContext);
    const [mode, setMode] = React.useState("normal");

    const [error, setError] = React.useState(null);
    const [latitude, setLatitude] = React.useState(0);
    const [longitude, setLongitude] = React.useState(0);
    const [targetlatitude, setTargetLatitude] = React.useState(props.latitude);
    const [targetlongitude, setTargetLongitude] = React.useState(props.longitude);
    const [heading, setHeading] = React.useState(0);
    const [altitude, setAltitude] = React.useState(0);
    const [maplatitide, setMapLatitude] = React.useState(0);
    const [maplongitude, setMapLongitude] = React.useState(0);
    const [updating, setUpdating] = React.useState(false);
    const [distance, setDistance] = React.useState(9999);
    const [, forceUpdate] = useReducer(x => x + 1, 0);


    useEffect(() => {
        console.log("useEffect");
        enableGPS();
        setupMap();
        mode == "test" && setupTestMode();
        //forceUpdate();
    }, []);

    useEffect(() => {
        console.log("location");
        gotoLocation();
        setTarget();
        setMyLocation();
        checkIfNear();
        window.UserMetrics && window.UserMetrics.metric("location", 1, { latitude, longitude });
    }, [latitude, longitude]);

    useEffect(() => {
        setTarget();
    }, [targetlatitude, targetlongitude]);

    function setupTestMode() {
        if (mode === "test") {
            console.log("setupTestMode");
            let i = 0;
            let testTimer = setInterval(() => {
                if (i < testPositions.length) {
                    setLatitude(testPositions[i].lat);
                    setLongitude(testPositions[i].lon);
                    i++;
                } else {
                    i = 0;
                }
            }, 500);
        }
    }

    function updatePosition(position) {
        console.log(position);
        console.log("Latitude: " + position.coords.latitude +
            "<br>Longitude: " + position.coords.longitude);
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        savePosition(position.coords.latitude, position.coords.longitude);
        setHeading(position.coords.heading);
        setAltitude(position.coords.altitude);
        setUpdating(false);
    }

    function checkIfNear() {
        if (calculateDistance() < 30) {
            console.log("You are near the target");
            //alert("You are near the target");
            if (props.onReachedTarget) {
                props.onReachedTarget();
            }
        }
    }

    /**
     * save to local storage
     */
    function savePosition(lat, lng) {
        if ((lat === 0) && (lng === 0)) {
            return;
        }
        localStorage.setItem('latitude', lat);
        localStorage.setItem('longitude', lng);
    }

    function loadPosition() {
        setLatitude(localStorage.getItem('latitude') || props.latitude + 0.001);
        setLongitude(localStorage.getItem('longitude') || props.longitude + 0.001);
    }

    function handleError(error) {
        console.log(error);
        setError("Error: " + error.code + " : " + error.message);
        if (latitude === 0) {
            // get from local storage
            setLatitude(localStorage.getItem('latitude') || props.latitude + 0.001);
            setLongitude(localStorage.getItem('longitude') || props.longitude + 0.001);
        }
    }

    function enableGPS() {
        console.log("enabledGPS");
        setUpdating(true);

        loadPosition();

        if (navigator.geolocation) {
            if (timer) {
                clearInterval(timer);
                timer = null;
            }
            timer = setInterval(() => {
                navigator.geolocation.getCurrentPosition(updatePosition, handleError, { enableHighAccuracy: true, timeout: 30000, maximumAge: 5000 });
                gotoLocation();
            }, 60000);

            if (!watchid) {
                watchid = navigator.geolocation.watchPosition(updatePosition, handleError, { enableHighAccuracy: true, timeout: 30000, maximumAge: 5000 });
            }
        } else {
            //alert("Geolocation is not supported by this browser. Use manual map positioning.");
            setError("Geolocation is not supported / disabled on this device. Use manual map positioning.");
        }


    }

    function gotoLocation() {
        // truncate lat lon to 0.0001 to counter drift and rapid changes
        if (themap && latitude !== 0 && longitude !== 0) {
            let lat = Math.round(latitude * 1000) / 1000;
            let lon = Math.round(longitude * 1000) / 1000;
            themap.setView([lat, lon], zoomlevel, { animate: false });
        }
    }

    function setupMap() {
        if (themap) {
            themap.off();
            themap.remove();
        }

        themap = L.map('map');

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(themap);
        setTarget();

        themap.on('click', function (e) {
            //alert("Lat, Lon : " + e.latlng.lat + ", " + e.latlng.lng);
            setLatitude(e.latlng.lat);
            setMapLatitude(e.latlng.lat);
            setLongitude(e.latlng.lng);
            setMapLongitude(e.latlng.lng);
            window.UserMetrics && window.UserMetrics.metric("manuallocation", 1, { latitude: e.latlng.lat, longitude: e.latlng.lng });
        });

        setupMarkers();
    }

    function setupMarkers() {
        gameContext.game.map(stage => {
            if (stage.complete && stage.type === "goto")
            // L.marker([stage.latitude, stage.longitude]).addTo(themap).bindPopup(stage.label);
            {
                L.marker([stage.latitude, stage.longitude], { icon: greenIcon }).addTo(themap);
            }
        })
    }

    function setMyLocation() {
        if (latitude === 0 || longitude === 0) {
            return;
        }

        if (locationMarker) {
            //themap.removeLayer(locationMarker);
            // move the marker
            locationMarker.setLatLng([latitude, longitude]);
        } else {
            locationMarker = L.marker([latitude, longitude]).addTo(themap);
        }

        // move the map so that both markers are visible
        try {
            if (targetMarker && locationMarker) {
                const group = new L.featureGroup([targetMarker, locationMarker]);
               themap.fitBounds(group.getBounds().pad(0.5), { animate: true, maxZoom: zoomlevel });
            }
        } catch (e) {
            console.log(e);
        }
    }

    function setTarget() {
        if (themap) {

            if (targetlatitude === 0 || targetlongitude === 0) {
                return;
            }

            if (targetMarker) {
                themap.removeLayer(targetMarker);
                themap.removeLayer(targetCircle);
            }

            targetMarker = L.marker([targetlatitude, targetlongitude]).addTo(themap);

            targetCircle = L.circle([targetlatitude, targetlongitude], {
                color: 'red',
                fillColor: '#f03',
                fillOpacity: 0.15,
                radius: 30
            }).addTo(themap);
        }
    }

    /**
     * distance in meters
     * @returns 
     */
    function calculateDistance() {
        const R = 6371e3; // metres
        const φ1 = latitude * Math.PI / 180; // φ, λ in radians
        const φ2 = targetlatitude * Math.PI / 180;
        const Δφ = (targetlatitude - latitude) * Math.PI / 180;
        const Δλ = (targetlongitude - longitude) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distance = R * c; // in metres
        setDistance(Math.round(distance));
        return Math.round(distance);
    }

    function calculateHeading() {
        const φ1 = latitude * Math.PI / 180; // φ, λ in radians
        const φ2 = targetlatitude * Math.PI / 180;
        const Δλ = (targetlongitude - longitude) * Math.PI / 180;

        const y = Math.sin(Δλ) * Math.cos(φ2);
        const x = Math.cos(φ1) * Math.sin(φ2) -
            Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ);
        const θ = Math.atan2(y, x);
        const heading = (θ * 180 / Math.PI + 360) % 360; // in degrees

        return Math.round(heading);
    }

    function getHeadingArrow() {
        return (
            <svg width="32" height="32" viewBox="0 0 100 100" style={{ transform: "rotate(" + calculateHeading() + "deg)" }}>
                <path d="M 50 0 L 100 100 L 50 80 L 0 100 Z" fill="red"></path>
            </svg>
        );
    }

    function resetMap() {
        console.log("reset");
        enableGPS();
        gotoLocation();
    }

    function round(value) {
        return Number(Math.round(value * 1000) / 1000);
    }

    function onSkip() {
        props.onSkip();
    }

    return (
        <header className="map">
            <div className='left'>
                <div id="map"></div>
                {maplatitide !== 0 && maplongitude !== 0 && <div>{Math.round(maplatitide * 10000) / 10000} {Math.round(maplongitude * 10000) / 10000}</div>}
            </div>
            <div className='hcontainer'>
                <div className='locationinfo'>
                    {props.mode === "debug" && <div>Heading {heading}</div>}
                    {props.mode === "debug" && <div>Altitude {altitude}</div>}
                    <div className='info'>
                        <p>You: {round(latitude)}, {round(longitude)}</p>
                    </div>
                    <div className='info'>
                        {props.label && <p>{props.label}</p>}
                        <p>Target: {round(targetlatitude)}, {round(targetlongitude)}</p>
                    </div>
                    <div className='info'>
                        <p>Distance: {distance}m </p>
                        <p>{updating && <div>Updating...</div>}</p>
                        {/* <GameButton to={resetMap} target={latitude} text="Reset" /> */}
                    </div>
                </div>
                <div className='locationarrow'>
                    {getHeadingArrow()}
                </div>
                {/* <div>{error}</div> */}
            </div>
            {<GameButton to={onSkip} target={latitude} >Skip</GameButton>}

        </header>

    );
}

export default Location;