import React from 'react';
import { getUser } from '../lib/user';
import MenuButton from './MenuButton';
import OnlineStatus from './OnlineStatus';

const Menu = (props) => {

    function getUserName() {
        if (getUser().name) {
            return <div className='username'>{getUser().name}</div>;
        } else {
            return "Guest";
        }
    }

    return (
        <div className='menu'>
            <div className='logo'>
                <img src="/rabbit.png" width="32" alt="rabbit" />
                <MenuButton to={props.setPage} target="home">GoPlay™</MenuButton>
            </div>
            <MenuButton to={props.setPage} target="games">Games</MenuButton>
            
                {!getUser().loggedin &&<MenuButton to={()=>props.setPage("login")}>Login</MenuButton>}                
                
                <MenuButton to={()=>props.setPage("help")}>Help</MenuButton>
                <MenuButton to={()=>props.setPage("profile")}>{getUserName()}</MenuButton>
        </div>
    );
}

export default Menu;