import React from 'react';

const MenuButton = (props) => {

    function doit() {
        console.log(props.target);
        props.to();
    }

    return (        
        <div className="menubutton" onClick={()=>props.to(props.target)}>{props.text}{props.children}</div>
    );
}

export default MenuButton;