import React, { useContext, useEffect } from 'react';
import Players from '../components/players/Players';
import GameButton from '../components/GameButton';
import { GameContext } from '../Context';

/**
 * Start Game Page
 * @param {*} props 
 * @returns 
 */
const Summary = (props) => {
    const gameContext = useContext(GameContext);
    const [message, setMessage] = React.useState("");

    useEffect(() => {
        if (!gameContext.game || !gameContext.game.length) {
            setMessage("Error - Game not started");
        }
    }, []);

    function renderStage(n) {
        if (!gameContext.game[n]) {
            return;
        }

        console.log(gameContext.game[n]);
        const stage = gameContext.game[n];
        return <div className='hcontainer'>
            <div className="hitem">{stage.complete ? "✅" : "❌"}</div>
            <div className="hitem" key={n}>{n}</div>
            <div className="hitem">{stage.stage} </div>            
            <div className='hitem'>score: {stage.score || 0}</div>
            <div className='hitem'>time: {stage.time || 0}</div>
        </div>
    }

    function renderStages() {
        if (!gameContext.game || !gameContext.game.length) {
            return;
        }

        const stages = gameContext.game.map((stage, index) => {
            return renderStage(index)
        });

        return (
            <div className="gridcontainer" >{stages}</div>
        );

    }

    return (
        <div>
            {message}
            {renderStages()}
        </div>
    );
};

export default Summary;