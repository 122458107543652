import React, { useEffect, useState } from 'react';
import './lockpick.css'; // Make sure to create a corresponding CSS file for styling
import { GameEngine, GameObject } from '../gameengine/gameengine';


let pin1 = new Image();
pin1.src = '/lockpick/p1.png';
let pin2 = new Image();
pin2.src = '/lockpick/p2.png';
let pin3 = new Image();
pin3.src = '/lockpick/p1.png';
let pin4 = new Image();
pin4.src = '/lockpick/p2.png';
let lock = new Image();
lock.src = '/lockpick/lock.png';

let LockPickObjects = [];

const LockPick = (props) => {
    const [initialPins, setInitialPins] = useState([0, 1, 2, 0]);
    const [pins, setPins] = useState([0, 1, 2, 0]);
    const [targetPins, setTargetPins] = useState([1, 0, 1, 0]);    

    useEffect(() => {
        console.log(pins);
    }, [pins]);

    function onGameClicked(e) {
        if (e.type === "pin") {
            //handlePinClick(e.index);
            pins[e.index]++;
            if (pins[e.index] > 2) {
                pins[e.index] = 0;
            }

            console.log("pins");
            console.log(initialPins);
            console.log(pins);
            console.log(targetPins);

            e.animateTo(e.x, e.oy + e.tag * pins[e.index]);

            if (pins.every((pin, index) => pin === targetPins[index])) {
                
                setTimeout(() => {
                    for (let i=0; i< pins.length; i++) {                
                        LockPickObjects[i].flash(5);
                    }                
                }, 400);

                setTimeout(() => {
                    props.onComplete();
                }, 2000);
            }
        }
    }

    function onInitialized(gobjects) {
        pins.forEach((pin, index) => {
            pins[index] = initialPins[index];
        });

        LockPickObjects = gobjects.filter(obj => obj.type === "pin");
        LockPickObjects.forEach((obj, index) => {
            obj.animateTo(obj.x, obj.oy + obj.tag * initialPins[index]);
        });
    }

    return (
        <div className="lockpick-container">
            <GameEngine onClick={onGameClicked} onInitialized={onInitialized} width={400} height={400}>
                <GameObject x={0} y={0} w={400} h={400} img={lock}></GameObject>
                <GameObject type="pin" index={0} x={80} y={40} w={45} h={170} tag={50} img={pin1}></GameObject>
                <GameObject type="pin" index={1} x={149} y={40} w={45} h={170} tag={50} img={pin2}></GameObject>
                <GameObject type="pin" index={2} x={218} y={40} w={45} h={170} tag={50} img={pin3}></GameObject>
                <GameObject type="pin" index={3} x={285} y={40} w={45} h={170} tag={50} img={pin4}></GameObject>
            </GameEngine>
        </div>
    );
};

export default LockPick;