const gphost = process.env.REACT_APP_HOSTNAME + "/goplay/wonderland😏/";

async function MakeRequest(url, body) {
    return fetch(gphost + url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            return ({ message: 'Network response was not ok.' });
        })
        .then(data => {
            return (data);
        })
        .catch(error => {
            return ({error:true, message:"An error occurred. Please try again."});
        });
};

export { MakeRequest, gphost };