import React from 'react';
import NavButton from '../components/NavButton';

const Help = (props) => {

    return (
        <div>
            <div className='vcontainer'>
                <img src="/rabbit.jpg" width="128"></img>
                <div className='helpcontainer'>
                    <h1>Help</h1>
                    <p>Welcome to Wonderland's GoPlay Outside™ Games!</p>
                    <p>Designed as fun social events, you play games and solve puzzles outside. Find clues and travel between waypoints to solve the puzzles.</p>
                    <p>Level of fitness: About an hour's eassy walk with many rest stops! Easy!</p>
                    <p>Some games are wheelchair friendly and pet friendly and will be clearly marked.</p>
                </div>
                <div className="helpcontainer">
                    <h1>Joining A Team</h1>
                    <p>Your team code is displayed in your profile</p>
                    <p>By default, everyone is in their own team of 1</p>
                    <p>To join a team, navigate to your profile and enter the team lead's team code. You can only be a member of one team at a time.</p>
                </div>
                <div className="helpcontainer">
                    <h1>Prizes</h1>
                    <p>There are numerous prizes handed out perdiodically. First place, Second Place, Best Dressed, Best Pets, and more!</p>
                    <p>Physical prizes will be awarded on the official day. Vouchers and Cash prizes will be sent to your email for claiming.</p>
                    <p>If you are playing for fun on a random day there are no official prizes.</p>
                </div>

                <div className="helpcontainer">
                    <h1>Checklist</h1>
                    <ul>
                        <li>Fully charged phone battery / battery pack</li>
                        <li>GPS Enabled</li>
                        <li>Water</li>
                        <li>A hat, comfy walking shoes</li>
                        <li>A sense of humour</li>
                    </ul>
                </div>
                <div className="helpcontainer">
                    <h1>Dress up</h1>
                    <p>Cosplay / Fancy Dress is optional but highly encouraged. There are prizes for best dressed people and pets.</p>
                </div>
                <div className="helpcontainer">
                    <h1>Resetting your game</h1>
                    <p>If something goes wrong, you can reset your game here</p>
                    <NavButton setPage={() => props.setPage("reset")}>Reset Game</NavButton>
                    Note: you will need to play the game again from the start or sacrifice score.
                </div>

                <div className="helpcontainer">
                    <h1>Setting up your GPS</h1>
                    <p>When prompted, click Allow:</p>
                    <p><img src="gps_allow.jpg"></img></p>
                    <NavButton setPage={() => props.setPage("test")}>Test your phone here</NavButton>
                    <p>Is the GPS Location in the right country, but far from you? You may need to enable Fine-Grained GPS Tracking in your settings</p>                    
                </div>
                <div className="helpcontainer">
                    <h1>Bypassing GPS Tracking</h1>
                    <p>While this defeats the core of the game, you can click on the map destination to move on.</p>
                    <p>Note: you will not receive any speed score, but you are still eligible for the Smartest Team and Fancy Dress prizes.</p>
                </div>
                <div className="helpcontainer">
                    <h1>How to Play</h1>
                    <p>Walk to the place directed on your map. Solve the puzzles, riddles, games, or extra geo challenges.</p>
                    <p>Do ask others for help and help those around you. Make friends!</p>
                </div>

            </div>


            <p></p>
        </div>
    );
}

export default Help;