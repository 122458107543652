import React from 'react';
import { MakeRequest } from '../lib/network';

const Register = () => {
    const [message, setMessage] = React.useState("");

    async function DoRegister(event) {
        event.preventDefault();
        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;

        // Validation
        if (!name) {
            setMessage("Name is required");
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setMessage("Invalid email format");
            return;
        }

        await MakeRequest('register', {
            name: name,
            email: email,
            password: password
        }).then((data) => {
            console.log('Success:', data);
            setMessage(data.message);
        }).catch((error) => {
            console.error('Error:', error);
            setMessage("Error");
        });
    }

    return (
        <div>
            <header className="App-header">
                <h2>Register</h2>
                <form className='form'>
                    <div>{message}</div>
                    <div className="formgroup">
                        <label className="formlabel" htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" required />
                    </div>
                    <div className="formgroup">
                        <label className="formlabel"  htmlFor="email">Email</label>
                        <input type="text" id="email" name="email" required />
                    </div>
                    <div className="formgroup">
                        <label className="formlabel"  htmlFor="password">Password</label>
                        <input type="password" id="password" name="password" required />
                    </div>
                    <button type="submit" onClick={DoRegister}>Register</button>
                </form>
            </header>
        </div>
    );
};

export default Register;