import React, { useEffect, useMemo, useState } from 'react';
import { MakeRequest } from '../../lib/network';
import { getUser } from '../../lib/user';
import "./players.css";
import MiniButton from '../minibutton/MiniButton';

const Players = ({groupid}) => {

    const [error, setError] = React.useState("");
    const [message, setMessage] = React.useState("loading...");
    const [players, setPlayers] = React.useState([]);
    const [groupID, setGroupID] = React.useState(groupid);

    useState(() => {
        setMessage("");
        console.log("new groupid")
        setGroupID(groupid || getUser().groupid);
    }, [groupid]);

    useEffect(() => {
        getGroupMembers();
    }, [groupID]);

    function getGroupMembers() {
        setError("");
        setMessage("loading...");
        MakeRequest('getgroupmembers', { groupid:groupID }).then((data) => {
            console.log('Success:', data);
            if (data && data.error) {
                setError(data.message);
            } else 
            if (data) {
                setPlayers(data?.members || []);
                setMessage("");
            }
        }).catch((error) => {
            console.error('Error:', error);
            setMessage("Error");
        });
    }

    function renderPlayers() {
        if (!players) return <div></div>;
        return players.map((member, index) => <span className="playername" key={index}>{member.name}{index <= players.length - 2 ? "," : ""}</span>);
    }

    function renderError() {
        if (error) {
            return <div className="error">{error} <MiniButton onClick={getGroupMembers} image="reload.png"></MiniButton></div>;
        }
    }

    return (
        <div>            
            {message} 
            {renderError()}
            {renderPlayers()}
        </div>
    );
};

export default Players;

