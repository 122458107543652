import React from 'react';
import { MakeRequest } from '../lib/network';
import { getUser, saveUser } from '../lib/user';

const Logout = (props) => {
    const [message, setMessage] = React.useState("Click To Log Out");

    function DoLogout(event) {
        
        setMessage("Logging out...");   
        event.preventDefault();
        console.log('Logout');        
        const user = getUser();
        MakeRequest('logout', {
            email: user.email
        }).then((data) => {
            // delete local storage
            localStorage.removeItem('user');
            console.log('Success:', data);
            setMessage(data.message);

            if (data.success) {
                window.location.href = '/';
            }

            props.setPage && props.setPage("login");
        })
        .catch((error) => {
            console.error('Error:', error);
            setMessage("Error");
        });
    }

    return (
        <div>
            <header className="App-header">
                <form>
                    <div>{message}</div>
                    <button type="submit" onClick={DoLogout}>Log Out</button>
                </form>
            </header>

        </div>
    );
};

export default Logout;