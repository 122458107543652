import React from 'react';
import { NavLink } from 'react-router-dom';
import NavButton from '../components/NavButton';

const Reset = (props) => {
    const [message, setMessage] = React.useState('');

    function reset() {
        // delete 'game' from local storage
        localStorage.removeItem('game');
        localStorage.removeItem('chapter');
        localStorage.removeItem('stime');
        setMessage('Your game is reset.');
    }

    return (
        <div className='vcontainer'>
            <NavButton setPage={reset}>Reset Current Game</NavButton>
            {message}
        </div>
    );
}

export default Reset;