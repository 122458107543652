import React, { useContext } from 'react';
import Players from '../components/players/Players';
import GameButton from '../components/GameButton';
import { GameContext } from '../Context';
import { getUser } from '../lib/user';

/**
 * Start Game Page
 * @param {*} props 
 * @returns 
 */
const Start = (props) => {

    const gameContext = useContext(GameContext);

    function startGame() {
        localStorage.setItem('gameName', gameContext.gameName);
        props.setPage(gameContext.gameName);
    }

    return (
        <div className='vcontainer'>
            <h1>Start</h1>
            <br></br>
            <h2>Team Members</h2>
            <Players></Players>
            <br></br>
            <GameButton to={startGame}>Play {gameContext.gameName.toUpperCase()}</GameButton>
        </div>
    );
};

export default Start;