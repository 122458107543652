import React from 'react';
import { MakeRequest } from '../lib/network';
import { saveUser } from '../lib/user';
import NavButton from '../components/NavButton';

const Login = (props) => {
    const [message, setMessage] = React.useState("");

    function DoLogin(event) {
        event.preventDefault();
        console.log('Login');
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
        MakeRequest('login', {
            email: email,
            password: password
        }).catch((error) => {
            console.error('Error:', error);
            setMessage("Error");
        }).then((data) => {
            console.log('Success:', data);
            setMessage(data.message);

            if (data.success) {
                saveUser(data.user);
                window.location.href = '/';
            }
        });
    }

    return (
        <div>
            <header className="App-header">
                <h2>Login</h2>
                <form>
                    <div>{message}</div>
                    <div>
                        <label htmlFor="email">Email:</label>
                        <input type="text" id="email" name="email" required />
                    </div>
                    <div>
                        <label htmlFor="password">Password:</label>
                        <input type="password" id="password" name="password" required />
                    </div>
                    <button type="submit" onClick={DoLogin}>Login</button>
                </form>
                <div>
                <h3>Register</h3>
                <NavButton setPage={()=>props.setPage("register")}>Register</NavButton>
                </div>
            </header>

            

        </div>
    );
};

export default Login;