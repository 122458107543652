import React from 'react';

const GameButton = ({ className, direction, text, target, to, children }) => {

    function doit() {
        console.log(target);
        if (target != -1) {
            to(target);
        }
    }

    function imgsource() {
        if (direction) {
            return <div>{text}<img src="/rabbitl.png" width="24" alt={text}></img></div>;
        } else {
            return <div><img src="/rabbit.png" width="24" alt={text}></img>{text}</div>;
        }
    }

    return (
        <div className={className || 'button'} onClick={doit}>{children}{imgsource()}</div>
    );
}

export default GameButton;