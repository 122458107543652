import React, { useState, useEffect } from 'react';

const GameTimer = ({ initialSeconds, onTimerEnd }) => {
    const [seconds, setSeconds] = useState(initialSeconds);

    useEffect(() => {
        if (seconds > 0) {
            const timerId = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);

            return () => clearInterval(timerId);
        } else if (seconds === 0) {
            onTimerEnd();
        }
    }, [seconds, onTimerEnd]);

    return (
        <div>
            <h1>T - {seconds} seconds</h1>
        </div>
    );
};

export default GameTimer;