import React from 'react';
import { NavLink } from 'react-router-dom';
import NavButton from '../components/NavButton';

const Prizes = (props) => {

    function openFacebook () {
        window.open('https://www.facebook.com/groups/1136811341450872');
    }

    return (
        <div>
            <header className="App-header">
                <div className='vcontainer'>
                <h1>Prizes</h1>             
                <p>Prizes are awarded to the top finishers in each game.</p>
                <p>Best Dressed</p>
                <p>Fastest</p>
                <p>Smartest</p>
                <p>Best Dressed Pets</p>
                <p>and other Random Prizes</p>
                <p>Upload your photos to our Facebook Group:</p> 
                    <NavButton setPage={openFacebook}>
                    <img src="/facebook.png" width="48"></img>
                    GoPlay Games
                    </NavButton>            
                    </div>    
            </header>            
        </div>
    );
}

export default Prizes;