import { type } from '@testing-library/user-event/dist/type';
import React from 'react';
import './minibutton.css';

const MiniButton = ({ onClick, image, children }) => {

    function doit() {        
        onClick();
    }

    function imgsource() {        
        if (typeof image === 'string') {
            return <img src={image} width="24" alt={children}></img>;
        }
    }

    return (
        <div>
            <div className={'minibutton'} onClick={doit}>{children}{imgsource()}</div>
        </div>
    );
}

export default MiniButton;