import React from 'react';
import Location from '../components/Location';

const Test = (props) => {

    return (
        <div className='test'>
            <Location latitude={ -33.9041358} longitude={18.4114505} mode="debug" onSkip={()=>props.setPage("home")}></Location>            
        </div>
    );
}

export default Test;