import React, { useEffect } from 'react';
import NavButton from '../components/NavButton';
import { getCurrentGame, getUser, saveUser } from '../lib/user';
import MenuButton from '../components/MenuButton';
import GameButton from '../components/GameButton';
import OnlineStatus from '../components/OnlineStatus';
import { MakeRequest } from '../lib/network';
import Players from '../components/players/Players';

const Profile = (props) => {

    const [message, setMessage] = React.useState("");
    const [groupid, setGroupID] = React.useState(getUser().groupid);
    const [index, setIndex] = React.useState(0);


    useEffect(() => {
    }, []);

    function joinGroupID() {
        if (groupid) {
            MakeRequest('setgroupid', { email: getUser().email, groupid: groupid }).then((data) => {
                console.log('Success:', data);
                const nuser = getUser();
                nuser.groupid = groupid;
                saveUser(nuser);
                setMessage(data.message);
                setIndex(index + 1);
            }).catch((error) => {
                console.error('Error:', error);
                setMessage("Error");
            });
        }
    }

    function onChangeID(e) {
        setGroupID(e.target.value);
    }

    return (
        <div className="profile">
            <OnlineStatus />
            <h1>Profile</h1>
            <div className="formgroup"> <div className='formlabel'><h3>Username</h3></div> <div className='formdata'>{getUser().name}</div></div>
            <div className="formgroup"> <div className='formlabel'><h3>Email</h3></div> <div className='formdata'>{getUser().email}</div></div>
            <div className="formgroup"> <div className='formlabel'><h3>Tickets</h3></div> <div className='formdata'>{getUser().tickets}</div></div>
            <div className="formgroup"> <div className='formlabel'><h3>Team Code</h3></div>
                <div className='formdata'>
                    <input id="groupid" type="text" value={groupid} onChange={onChangeID} />
                </div>
                <GameButton className="button" to={joinGroupID}><h3>Join Team</h3></GameButton>
            </div>
            <div className="formgroup"> <div className='formlabel'><h3>Team Members</h3></div>
                <div className='formdata'>
                    <Players groupid={groupid} key={index}></Players>
                </div>
            </div>
            <div className="formgroup"> <div className='formlabel'><h3>Current Game</h3></div>
                <div className='formdata'>
                    {getCurrentGame()}
                </div>
            </div>
            <div className="formgroup">
                <div className='formlabel'><h3>Reset</h3></div>
                <div className='formdata'><NavButton setPage={() => props.setPage("reset")}>Reset Game</NavButton>
                </div>
                <div className='formlabel'>Note: you will need to play the game again from the start or sacrifice your score.</div>
            </div>
            <div className='formgroup'>
                <div className='formlabel'><h3>Summary</h3></div>
                <div className='formdata'><NavButton setPage={() => props.setPage("summary")}>Game Summary</NavButton></div>
            </div>
            {message}
            {getUser().loggedin && <NavButton className="button" setPage={() => props.setPage("logout")}>Logout</NavButton>}
        </div>
    );
};


export default Profile;

