import React from 'react';
import { NavLink } from 'react-router-dom';
import NavButton from './NavButton';

const CheckList = (props) => {
    return (
        <div className='container'>
            <div className='checklist'>
                <h1>Checklist</h1>
                <ul>
                    <li>Fully charged phone battery</li>
                    <li>GPS Enabled</li>
                    <li>Water</li>
                    <li>A hat, comfy walking shoes</li>
                    <li>A sense of humour</li>
                    <li>Tip: Bring a battery pack and snacks</li>
                </ul>
            </div>
            <NavButton setPage={() => props.setPage("test")}>Test your phone here</NavButton>
            <p>Note: It is possible to play with GPS turned off but this will degrade the experience</p>
            <p>Team Leads - Please help players get to the start, with their name displayed in the title bar.</p>
        </div>
    );
}

export default CheckList;