import React, { useContext } from 'react';
import NavButton from '../components/NavButton';
import { GameContext } from '../Context';

const Games = (props) => {

    const gameContext = useContext(GameContext);

    function gotoGame(gameName) {
        gameContext.setGameName(gameName);
        props.setPage("start");
    }

    return (
        <div>
            <header className="App-header">
                <div className="imgplaceholder"><img src="/goplay_banner.jpg" className="gameimage"></img></div>
            </header>
            <h1>Upcoming Geo Games</h1>
            <div className='vcontainer'>
                <div>Cape Town 2024/2025</div>
                <div>NOW PLAYING:</div>
                <NavButton className="NavLink" setPage={() => gotoGame("ispy")}>I, Spy</NavButton>
                <div className='gameattributes'><img className="gameicon" src="/familyfriendly.jpg" width="52"></img><img className="gameicon" src="/wheelchair.jpg" width="52"></img><img className="gameicon" src="/dogfriendly.jpg" width="52"></img> +-1 Hour.</div>
                <br></br>
                <div>Coming soon</div>
                <NavButton className="NavLink" setPage={() => props.setPage("dragontamer")}>Dragon Tamer</NavButton>
                <div className='gameattributes'>Intermediate <img className="gameicon" src="/dogfriendly.jpg" width="52"></img> +-1 Hour.</div>
                <br></br>
                <NavButton className="NavLink" setPage={() => props.setPage("mutant")}>Mutant</NavButton>
                <div className='gameattributes'>Advanced +-2 Hours</div>
                <br></br>
                <NavButton className="NavLink" setPage={() => props.setPage("timemachine")}>Time Machine</NavButton>
                <br></br>
                <div>Testing</div>
                <NavButton className="NavLink" setPage={() => props.setPage("tester")}>Tester</NavButton>
                <div className='gameattributes'>Just For Testing  +-10 Min</div>
            </div>

        </div>
    );
}

export default Games;