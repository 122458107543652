import React from 'react';

const GameHint = (props) => {
    const [showHint, setShowHint] = React.useState(false);

    function doit() {
        if (!showHint) {
            props.penalty();
        }
        setShowHint(true);
    }

    return (
        <div className="hint">
        <div className='button' onClick={doit}>Hint (-1 Point)</div>
        {showHint && <p>{props.hint}</p>}
        </div>
    );
}

export default GameHint;