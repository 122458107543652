import React, { createContext, useState } from 'react';
import OnlineStatus from './components/OnlineStatus';

// Create a Context
const GameContext = createContext();

// Create a provider component
const GameContextProvider = ({ children }) => {
    const [time, setTime] = useState(0);
    const [online, setOnline] = useState(true);
    const [admin, setAdmin] = useState(process.env.REACT_APP_ADMIN);
    const [game, setGame] = useState([]);
    const [gameName, setGameName] = useState("");

    return (
        <GameContext.Provider value={{ admin, setAdmin, time, setTime, online, setOnline, game, setGame, gameName, setGameName }}>
            <OnlineStatus></OnlineStatus>
            {children}
        </GameContext.Provider>
    );
};

export { GameContext, GameContextProvider };