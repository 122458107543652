function saveUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
}

function getUser() {
    const user = localStorage.getItem('user');
    if (!user) {
        return {loggedin:false};
    }
    const juser = JSON.parse(user);
    window.UserMetrics._clientid = juser.name || "guest";
    return juser;
}

function clearUser() {
    localStorage.removeItem('user');
}

function getCurrentGame() {
    return localStorage.getItem('gameName');
}

export { saveUser, getUser, clearUser, getCurrentGame };