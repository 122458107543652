import { useEffect, useState } from "react";
import './gameengine.css';

class GameObject {
    visible = true;
    x = 0; y = 0; w = 1; h = 1; img;
    tx = 0;
    ty = 0;
    ox = 0;
    oy = 0;
    speed = 1;

    flashtimes = 5;
    flashedtimes = 0;
    flashon = false;
    flashcounter = 5;

    constructor() {
    }

    pulse() {
        // move the object to the target
        const dx = this.tx - this.x;
        const dy = this.ty - this.y;
        const dist = Math.sqrt(dx * dx + dy * dy);
        if (dist < 1) {
            return;
        }
        const nx = dx / dist * this.speed;
        const ny = dy / dist * this.speed;
        this.x += nx;
        this.y += ny;
    }

    render(ctx, frame) {
        if (this.flashon) {
            if (frame % 40 === 0) {
                this.visible = !this.visible;
            }
        }
        this.visible && ctx.drawImage(this.img, this.x, this.y, this.w, this.h);
    }

    /**
     * Render the object into the click canvas. 
     * The color is used to determine which object is clicked
     * @param {*} ctx 
     */
    renderClick(ctx) {
        const col = `rgb(${this._id}, 0, 0)`;
        ctx.fillRect(this.x, this.y, this.w, this.h);
    }

    animateTo(x, y) {
        this.tx = x;
        this.ty = y;
    }

    flash(numFlashes) {
        this.visible = false;
        this.flashon = true;
        this.flashtimes = numFlashes;
    }
}

let _canvas;
let _ctx;
let xcanvas;
let xctx;
let canvasid = "game-canvas";
let xcanvasid = "click-canvas";
let selectedObject = null;
let delta = 0;
let gameFrame = 0;
let gameObjects = [];

const GameEngine = (props) => {

    //const [objects, setObjects] = useState([]);

    useEffect(() => {
        console.log("gameengine");
        setupGame();
        props.onInitialized && props.onInitialized(gameObjects);
        return () => {
            console.log("/gameengine");
            selectedObject = null;
            _canvas.removeEventListener('click', onClick);
            _canvas = null;
            _ctx = null;
            xcanvas = null;
            xctx = null;
            gameObjects = [];
        }

    }, []);

    useEffect(() => {
        render();
    }, [selectedObject]);

    function setupGame() {
        if (!props.children) {
            return;
        }

        while (gameObjects.length) {
            gameObjects.pop();
        }
        gameObjects.length = 0;

        props.children.forEach(child => {
            const go = new GameObject();
            Object.entries(child.props).forEach(([key, value]) => {
                go[key] = value;
            });

            go.ox = go.x;
            go.oy = go.y;
            go.tx = go.x;
            go.ty = go.y;
            go._id = gameObjects.length;
            gameObjects.push(go);
        });

        if (!_canvas) {
            _canvas = document.getElementById(canvasid);
            _canvas.addEventListener('click', onClick);
        }
        if (!_canvas) {
            throw new Error('Canvas not found');
        }

        if (!xcanvas) {
            xcanvas = document.getElementById(xcanvasid);
        }

        if (!xcanvas) {
            throw new Error('Canvas not found');
        }

        if (!_ctx) {
            _ctx = _canvas.getContext('2d');
            _canvas.width = props.width || 500;
            _canvas.height = props.height || 500;
            _ctx.fillStyle = 'black';
            _ctx.fillRect(0, 0, _canvas.width, _canvas.height);
        }

        if (!xctx) {
            xctx = xcanvas.getContext('2d', { willReadFrequently: true });
            xcanvas.width = 500;
            xcanvas.height = 500;
            xctx.fillStyle = 'black';
            xctx.fillRect(0, 0, xcanvas.width, xcanvas.height);
        }

        // start the render loop
        renderLoop();
    }

    function renderLoop(fdelta = 0) {
        delta = fdelta;
        render();
        setTimeout(() => {
            requestAnimationFrame(renderLoop);
        }, 10);

    }

    function render() {
        //console.log('render');
        if (!_ctx) {
            return;
        }
        gameFrame++;

        _ctx.fillStyle = 'black';
        _ctx.fillRect(0, 0, _canvas.width, _canvas.height);

        xctx.fillStyle = 'white';
        xctx.fillRect(0, 0, _canvas.width, _canvas.height);
        for (let i = 0; i < gameObjects.length; i++) {
            gameObjects[i].pulse();
           // console.log(gameObjects[i]._id, gameObjects[i].visible);

            //_ctx.drawImage(objects[i].img, objects[i].x, objects[i].y, objects[i].w, objects[i].h);
            gameObjects[i].render(_ctx, gameFrame);
            // calculate a color based on the index

            const col = `rgb(${i}, 0, 0)`;
            xctx.fillStyle = col;
            //xctx.fillRect(objects[i].x, objects[i].y, objects[i].w, objects[i].h);
            gameObjects[i].renderClick(xctx);
        }

        if (selectedObject) {
            _ctx.strokeStyle = 'red';
            //_ctx.fillRect(0, 0, _canvas.width, _canvas.height);
            _ctx.strokeRect(selectedObject.x, selectedObject.y, selectedObject.w, selectedObject.h);
        }
    }

    function onClick(e) {
        //console.log(e);
        if (!e.offsetX) {
            return;
        }

        // get the color of the pixel on the xctx
        const pixel = xctx.getImageData(e.offsetX, e.offsetY, 1, 1).data;
        //console.log(pixel);
        // find the object that has that color
        // the object is the red index
        const obj = gameObjects[pixel[0]];

        if (obj) {
            obj.hit = true;
            obj.viewx = e.offsetX;
            obj.viewy = e.offsetY;
            props.onClick && props.onClick(obj);
        } else {
            props.onClick && props.onClick({ hit: false, type: "view", viewx: e.offsetX, viewy: e.offsetY });
        }

        selectedObject = obj;

        //if (obj?._onClick) {
        //  obj._onClick(obj);
        //}

        //console.log(obj);

    }

    return (
        <div>
            <canvas className="game-canvas" id="game-canvas" onClick={onClick}></canvas>
            <canvas className="click-canvas" id="click-canvas"></canvas>
        </div>
    );
}

export { GameEngine, GameObject };