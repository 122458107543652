import React from 'react';
import './scrollarea.css';

const ScrollArea = (props) => {
    return (
        <div className='scrollarea'>
           {props.children}
        </div>
    );
}

export default ScrollArea;