import { useContext, useEffect, useSyncExternalStore } from 'react';
import { GameContext } from '../Context';

function OnlineStatus() {
    const gameContext = useContext(GameContext);
    const isOnline = useSyncExternalStore(subscribe, getSnapshot);   

    useEffect(() => {
        gameContext.setOnline(isOnline);
    }, [isOnline]);

    function getSnapshot() {
        return navigator.onLine;
    }

    function subscribe(setter) {
        window.addEventListener('online', () => setter(true));
        window.addEventListener('offline', () => setter(false));

        return () => {
            window.removeEventListener('online', () => setter(true));
            window.removeEventListener('offline', () => setter(false));
        };
    }

    //✅Online
    return <div className='online'>{isOnline ? '' : '❌ Disconnected'}</div>;
}

export default OnlineStatus;